import React, { useState } from "react";
import getStripe from "utils/stripe";
import GridGallery from "react-grid-gallery";
import Loader from "components/loader";
import { useTranslateContext, useTranslations } from "gatsby-plugin-translate";

import separator from "images/separator.png";

// const formatPrice = (amount, currency) => {
//   let price = (amount / 100).toFixed(2);
//   let numberFormat = new Intl.NumberFormat(["en-US"], {
//     style: "currency",
//     currency: currency,
//     currencyDisplay: "symbol",
//   });
//   return numberFormat.format(price);
// };

const Card = ({ product }) => {
  const [loading, setLoading] = useState(false);
  const { language } = useTranslateContext();
  const t = useTranslations();

  const handleSubmit = async (event, price) => {
    event.preventDefault();
    setLoading(true);

    const stripe = await getStripe();

    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price, quantity: 1 }],
      shippingAddressCollection: {
        allowedCountries: ["PT"]
      },
      locale: language,
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/shop`
    });

    if (error) {
      console.warn("Error:", error);
      setLoading(false);
    }
  };

  return (
    <div className="product-container">
      <div className="product-card">
        <form
          onSubmit={e => handleSubmit(e, product.default_price)}
          className="product-form"
        >
          <fieldset style={{ border: "none", paddingBottom: "40px" }}>
            <legend>
              <h3 style={{
                lineHeight: '24px',
                padding: '10px'
              }}>{product.name}</h3>
            </legend>
            <GridGallery
              images={[
                { src: product.images[0], thumbnail: product.images[0] }
              ]}
              enableImageSelection={false}
            />
            <label className="product-description">
              <h6>
                {product.description.split(" - ").map((d, index) => (
                  <div key={`description-${index}`}>{d}</div>
                ))}
              </h6>
            </label>
            {/* <label>
              <h2 name="priceSelect">
                -
                {formatPrice(product.price.unit_amount, product.price.currency)}
              </h2>
            </label> */}
          </fieldset>
          {/* <button
            disabled={loading}
            type="submit"
            className={`button${loading ? " loading" : ""}`}
          >
            {loading ? <Loader /> : t`COMPRAR`}
          </button> */}
          <a type="submit" className="button"
            style={{
              textDecoration: "none",
              whiteSpace: 'nowrap',
              padding: '1rem'
            }}
            href={language !== "pt" ? `/${language}?subject=${product.name}#contact` : `/?subject=${product.name}#contact`}
          >
            {loading ? <Loader /> : t`PREÇO SOB CONSULTA`}
          </a>
        </form>
      </div>
      <img className="separator" src={separator} alt="separator" />
    </div >
  );
};

export default Card;
